<script>
export default {
  name: "Company",
  computed: {
    spaces() {
      return this.$store.state.Manager.spaces;
    },
    spaceCompany() {
      if (this.spaces.length) {
        return this.spaces[0].space_company;
      }
      return null;
    },
  },
};
</script>

<template>
  <div class="row" id="company">
    <div id="left-nav" class="col-12 col-md-2 mt-3">
      <div id="brand" class="row text-center">
        <h4 class="col-12 col-md fw-bold">
          <div class="container py-2">
            <template v-if="spaces && spaces.length > 0">
              {{ spaceCompany.company_name }}
            </template>
            <template v-else>---</template>
          </div>
        </h4>
      </div>

      <div id="mid_nav" class="row py-2">
        <div class="col-md-12">
          <router-link :to="{ name: 'Manager-Dashboard' }">
            <i class="fas fa-home"></i>
            <span class="d-sm-inline">Dashboard</span>
          </router-link>
        </div>

        <div class="col-md-12">
          <router-link :to="{ name: 'ApproveBookings' }">
            <i class="fas fa-clipboard-list"></i>
            <span class="d-sm-inline">Pending Approvals</span>
          </router-link>
        </div>

        <div class="col-md-12">
          <router-link :to="{ name: 'BillingPaid' }">
            <i class="fas fa-money-bill"></i>
            <span class="d-none d-sm-inline">Billing</span>
            <span style="font-size: 12px" class="d-sm-none">Billing</span>
          </router-link>
        </div>
        <div class="col-md-12">
          <router-link :to="{ name: 'PaymentPolicies' }">
            <i class="fas fa-money-bill"></i>
            <span class="d-none d-sm-inline">Payment Policies</span>
            <span style="font-size: 12px" class="d-sm-none">Policies</span>
          </router-link>
        </div>
        <div class="col-md-12">
          <router-link :to="{ name: 'Clients' }" title="Clients">
            <i class="fas fa-users"></i>
            <span class="d-none d-sm-inline">Clients</span>
            <span style="font-size: 12px" class="d-sm-none">Clients</span>
          </router-link>
        </div>
        <!--     <div class="col col-md-12 bottom_nav"> -->
        <!-- <i class="fas fa-cog"></i> -->
        <!--         <router-link :to="{name:'Settings'}">Settings</router-link> -->
        <!--     </div> -->
      </div>
    </div>

    <div id="right-nav" class="col">
      <div class="row">
        <router-view />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#company {
  #left-nav {
    h4 div {
      background: white;
      border: 1px solid #eee;
    }
    #mid_nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      & > div {
        display: flex;
        flex-direction: column;
        width: auto;
        @media (min-width: 768px) {
          width: 100%;
        }
        a {
          padding: 10px;
          border-radius: 3px;
          margin: 5px;
          background: rgb(180, 190, 195);
          color: white;
          text-decoration: none;
          color: rgba(0, 0, 0, 0.55);
          &.router-link-active {
            background: rgb(230, 240, 245);
            color: #009d6b;
            font-weight: bold;
          }
          i {
            margin-right: 4px;
            color: inherit;
          }
        }
      }
    }
  }
}
</style>
